import { BoxProps, Text } from '@chakra-ui/core'
import { Question } from '@guess-the-rose/firestore'
import { Box, Button } from '@guess-the-rose/web-shared'
import { useFormikContext } from 'formik'
import React, { FC } from 'react'

import { QuestionHeader } from './sharedQuestionComponents'

type MultiSelectQuestionProps = BoxProps & {
  question: Question
  maxNumberOfSelections: number
}

export const MultiSelectOption: FC<{
  onClick: () => void
  isSelected: boolean
}> = ({ children, onClick, isSelected }) => {
  return (
    <Button
      backgroundColor="#FCFCFC !important"
      borderColor="#F0F0F0 !important"
      height="52px"
      mb="2"
      onClick={onClick}
      type="button"
      variant={isSelected ? 'solid' : 'outline'}
      width="100%"
    >
      {children}
    </Button>
  )
}

export const MultiSelectQuestion: FC<MultiSelectQuestionProps> = ({
  question,
  maxNumberOfSelections,
  ...rest
}) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<any>()
  const selectValues = values[question._id]

  return (
    <Box {...rest}>
      <QuestionHeader question={question} />
      {question.questionConfiguration.multiSelectOptions!.map(
        (option, index) => (
          <MultiSelectOption
            isSelected={selectValues.includes(index)}
            key={index}
            onClick={() => {
              setFieldValue(
                question._id,
                selectValues.includes(index)
                  ? selectValues.filter((val: number) => val !== index)
                  : [...selectValues, index],
              )
              setTimeout(() => setFieldTouched(question._id, true))
            }}
          >
            {option}
          </MultiSelectOption>
        ),
      )}
      {maxNumberOfSelections > 0 && (
        <Text color="gray.500" fontStyle="italic" mt="4" textAlign="center">
          {selectValues.length} of {maxNumberOfSelections} Selections Made
        </Text>
      )}
    </Box>
  )
}
