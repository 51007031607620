import {
  getIsQuestionAnswered,
  getPrettyCorrectAnswerByQuestionType,
  getPrettyUserAnswerByQuestionType,
  getScoreForQuestion,
} from '@guess-the-rose/core'
import {
  Contestant,
  EpisodeEntryDocument,
  Question,
  SanityEpisode,
} from '@guess-the-rose/firestore'
import { isEmpty, isUndefined, sortBy } from '@guess-the-rose/utils'
import {
  Box,
  Button,
  Divider as CoreDivider,
  EmptySection,
  Heading,
  Icon,
  Image,
  Text,
  useDisclosure,
} from '@guess-the-rose/web-shared'
import { Document, useDocument } from '@nandorojo/swr-firestore'
import Link from 'next/link'
import React, { FC } from 'react'

import { QUESTION_ORDER_ARRAY } from '../const'
import { EntryDocumentWithID } from '../context'
import { useSanity } from '../hooks/useSanity'
import { handleLoadingError } from '../lib'
import { ContestantModal } from './ContestantModal'
import { SanityEpisodePicksSection } from './PicksSection'
import { CorrectIcon } from './questionComponents/sharedQuestionComponents'
import { ShareGroupModal } from './ShareGroupModal'

const NotesAnswer: FC = ({ children }) => {
  return (
    <Text
      color="gray.500"
      fontSize="2"
      fontStyle="italic"
      my="2"
      textAlign="left"
    >
      Notes: {children}
    </Text>
  )
}

const RosePickContestant: FC<{
  contestant: Contestant
  question: Question
}> = ({ contestant, question }) => {
  const { isOpen, onClose, onOpen } = useDisclosure()

  return (
    <Box
      alignItems="center"
      borderBottom="1px solid"
      borderColor="gray.200"
      display="flex"
      justifyContent="space-between"
      py="2"
    >
      <Box alignItems="center" display="flex">
        <Image
          alt={contestant.images[0].caption}
          borderRadius="5px"
          height="40px"
          mr="4"
          objectFit="cover"
          onClick={onOpen}
          src={contestant.images[0].url}
          width="40px"
        />
        <Box>
          <Text textTransform="uppercase">{contestant.name}</Text>
        </Box>
        <ContestantModal
          contestant={contestant}
          isOpen={isOpen}
          onClose={onClose}
        />
      </Box>
      <Box>
        {question.questionConfiguration.weeklyLineupAnswer?.includes(
          contestant._id,
        ) ? (
          <CorrectIcon points={question.points} />
        ) : (
          <Icon color="gray.300" name="incorrect" size="25px" />
        )}
      </Box>
    </Box>
  )
}

export const RosePicks: FC<{
  episode: SanityEpisode
  episodeEntry: Document<EpisodeEntryDocument>
}> = ({ episode, episodeEntry }) => {
  const weeklyPick = episode.questions.find(
    (q) => q.questionConfiguration.questionType === 'weeklyLineup',
  )

  if (!weeklyPick) return null
  const chosenContestants = episodeEntry.responses[weeklyPick._id]

  return (
    <Box mb="10">
      <Heading as="h2" mb="4" size="md">
        Rose Picks
      </Heading>
      <Box>
        {chosenContestants.map((id: string) => {
          const contestantData = episode.contestants.find((c) => c._id === id)
          if (!contestantData) return null

          return (
            <RosePickContestant
              contestant={contestantData}
              key={id}
              question={weeklyPick}
            />
          )
        })}
      </Box>
      {weeklyPick.questionConfiguration.notesAnswer ? (
        <NotesAnswer>
          {weeklyPick.questionConfiguration.notesAnswer}
        </NotesAnswer>
      ) : null}
    </Box>
  )
}

const EpisodeQuestion: FC<{
  question: Question
  episodeEntry: Document<EpisodeEntryDocument>
}> = ({ question, episodeEntry }) => {
  const userAnswer = episodeEntry.responses[question._id]
  const isQuestionAnswered = getIsQuestionAnswered(question)
  const score = getScoreForQuestion(question, userAnswer)
  const prettyAnswer = getPrettyCorrectAnswerByQuestionType(question)
  const isPrettyAnswerEmpty =
    !prettyAnswer || (Array.isArray(prettyAnswer) && prettyAnswer.length === 0)

  return (
    <Box borderBottom="1px solid" borderColor="gray.200" py="2">
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <Box alignItems="center" display="flex">
          <Box>
            <Text mb="2">{question.question}</Text>
            <Text color="gray.500" fontSize="0.8rem">
              {!isUndefined(userAnswer)
                ? getPrettyUserAnswerByQuestionType(userAnswer, question)
                : 'Not answered.'}
            </Text>
            {isQuestionAnswered ? (
              <Text color="gray.500" fontSize="0.8rem">
                Correct Answer:{' '}
                {isPrettyAnswerEmpty ? 'No question was right' : prettyAnswer}
              </Text>
            ) : null}
          </Box>
        </Box>
        {isQuestionAnswered ? (
          <Box flexShrink={0} marginLeft="1rem">
            {score > 0 ? (
              <CorrectIcon points={score} />
            ) : (
              <Icon color="gray.300" name="incorrect" size="25px" />
            )}
          </Box>
        ) : null}
      </Box>
      {question.questionConfiguration.notesAnswer ? (
        <NotesAnswer>{question.questionConfiguration.notesAnswer}</NotesAnswer>
      ) : null}
    </Box>
  )
}

const EpisodeQuestions: FC<{
  episode: SanityEpisode
  episodeEntry: Document<EpisodeEntryDocument>
}> = ({ episode, episodeEntry }) => {
  const sortedQuestions = sortBy(
    (q) => QUESTION_ORDER_ARRAY.indexOf(q.questionConfiguration.questionType),
    episode.questions,
  ).filter((q) => q.questionConfiguration.questionType !== 'weeklyLineup')

  return (
    <Box>
      <Heading as="h2" mb="4" size="md">
        Episode Questions
      </Heading>
      <Box>
        {sortedQuestions.map((q) => (
          <EpisodeQuestion
            episodeEntry={episodeEntry}
            key={q._id}
            question={q}
          />
        ))}
      </Box>
    </Box>
  )
}

const Divider: FC = () => <CoreDivider my="6" />

export const PickSectionPreseason: FC<{ groupID: string }> = ({ groupID }) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  return (
    <>
      <Box
        display="flex"
        flexDir="column"
        justifyContent="center"
        maxWidth="400px"
        mx="auto"
        px="6"
        py="6"
      >
        <Icon
          color="gray.300"
          margin="0 auto"
          mb="6"
          name="brandmarkMono"
          size="72px"
        />
        <Text mb="4" textAlign="center">
          Picks will be available soon - check back after episode one.
        </Text>
        <Text textAlign="center">
          There’s still time to{' '}
          <Button
            color="pink.500"
            display="inline"
            height="auto"
            letterSpacing="0"
            onClick={onOpen}
            padding="0"
            size="xs"
            textDecoration="underline"
            textTransform="lowercase"
            type="button"
            variant="link"
            verticalAlign="inherit"
            width="auto"
          >
            invite friends
          </Button>
          !
        </Text>
        <Divider />
        <Heading mb="4" textAlign="center">
          How it Works
        </Heading>
        <Text mb="10" textAlign="center">
          Gameplay starts after episode one. Until then, sit back and scout out
          this season’s contestants!
        </Text>
        <Icon
          color="gray.300"
          margin="0 auto"
          mb="6"
          name="brandmark"
          size="72px"
        />
        <Heading
          as="h3"
          mb="1"
          size="md"
          textAlign="center"
          textTransform="uppercase"
        >
          One true love
        </Heading>
        <Text color="pink.500" fontWeight="bold" mb="4" textAlign="center">
          +50 points
        </Text>
        <Text mb="10" textAlign="center">
          Can you predict who will receive this season’s final rose? Lock in
          your selection after episode one.
        </Text>
        <Image
          alt="weekly picks picker"
          marginBottom="6"
          maxWidth="400px"
          mx="auto"
          objectFit="contain"
          src={'/images/weeklyPicks.png'}
          width="80%"
        />
        <Heading
          as="h3"
          mb="1"
          size="md"
          textAlign="center"
          textTransform="uppercase"
        >
          Weekly Rose Predictions
        </Heading>
        <Text color="pink.500" fontWeight="bold" mb="4" textAlign="center">
          +5 points
        </Text>
        <Text mb="10" textAlign="center">
          Each week, predict which contestants will receive a rose. If you’re
          correct, tack on 5 points for each correct answer.
        </Text>
        <Icon
          color="gray.300"
          margin="0 auto"
          mb="6"
          name="questions"
          size="72px"
        />
        <Heading
          as="h3"
          mb="1"
          size="md"
          textAlign="center"
          textTransform="uppercase"
        >
          Weekly Bonus Questions
        </Heading>
        <Text color="pink.500" fontWeight="bold" mb="4" textAlign="center">
          +10 points
        </Text>
        <Text mb="10" textAlign="center">
          Each week, we’ll ask a few questions about the upcoming episode. Some
          will be serious - some will be fun. Guess correctly for 10 points.
        </Text>
        <Button
          alignSelf="center"
          as="a"
          display="flex"
          // @ts-ignore
          href="/how-it-works"
          variant="outline"
        >
          More Details
        </Button>
      </Box>
      <ShareGroupModal groupID={groupID} isOpen={isOpen} onClose={onClose} />
    </>
  )
}

export const PickSectionOpen: FC<{
  episode: SanityEpisodePicksSection
  entry: EntryDocumentWithID
}> = ({ episode, entry }) => {
  const { data, error } = useDocument<EpisodeEntryDocument>(
    `/groups/${entry.groupID}/entries/${entry.id}/episodeEntries/${episode._id}`,
  )

  const loadingError = handleLoadingError({ data, error, skeletons: 1 })

  return (
    <Box
      display="flex"
      flexDir="column"
      justifyContent="center"
      maxWidth="400px"
      mx="auto"
      px="6"
      py="6"
    >
      <Icon
        color="gray.300"
        margin="0 auto"
        mb="6"
        name="brandmark"
        size="72px"
      />
      {loadingError ?? (
        <>
          <Text mb="4" textAlign="center">
            {isEmpty(data!.responses)
              ? `Episode ${episode.episodeNumberSeason} picks are now open! Make your
            selections before the start of the next episode.`
              : `Your picks have been submitted. You can edit them until the start of the next episode.`}
          </Text>
          <Link
            as={`/picks/${entry.groupID}/${entry.id}/${episode._id}`}
            href="/picks/[group]/[entry]/[episode]"
          >
            {isEmpty(data!.responses) ? (
              <Button
                alignSelf="center"
                as="a"
                display="flex"
                // @ts-ignore
                variant="solid"
              >
                Make picks
              </Button>
            ) : (
              <Button
                alignSelf="center"
                as="a"
                display="flex"
                // @ts-ignore
                variant="outline"
              >
                Edit Picks
              </Button>
            )}
          </Link>
        </>
      )}
    </Box>
  )
}

export const PicksSectionScored: FC<{
  episode: SanityEpisodePicksSection
  entry: EntryDocumentWithID
}> = ({ episode, entry }) => {
  const { data, error } = useDocument<EpisodeEntryDocument>(
    `/groups/${entry.groupID}/entries/${entry.id}/episodeEntries/${episode._id}`,
  )
  const { data: sanityEpisode, error: sanityEpisodeError } = useSanity<
    SanityEpisode
  >(`*[_type == "episode" && _id == "${episode._id}" && !(_id in path("drafts.**"))]{
  _id,
  name,
  description,
  episodeNumberSeason,
  airDate,
  season->{
    name,
    _id,
    show,
    starring->{
      _id,
      name,
      images[]{
        caption,
        "url": asset->{url}.url
      }
    }
  },
  contestants[]->{
    _id,
    name,
    body,
    age,
    career,
    location,
    excerpt,
    twitter,
    instagram,
    website,
    youtube,
    images[]{
      caption,
      "url": asset->{url}.url
    },
    seasons[]->{
      _id,
      name,
      seasonNumber,
      show
    }
  },
  "questions": *[_type=='question' && references(^._id)]{
    _id,
    question,
    helpText,
    points,
    questionConfiguration{
      questionType,
      "weeklyLineupAnswer": weeklyLineupAnswer[]._ref,
      numericAnswer,
      booleanAnswer,
      weeklyLineupNumberOfChoices,
      multipleChoiceOptions,
      multipleChoiceAnswer,
      multiSelectOptions,
      multiSelectAnswer,
      notesAnswer
    }
  }
}[0]
`)

  const loadingError = handleLoadingError({
    data: data && sanityEpisode,
    error: error && sanityEpisodeError,
    skeletons: 1,
  })

  return (
    loadingError ?? (
      <Box>
        <Box>
          <Text
            color="pink.400"
            fontSize="1.3rem"
            fontWeight="bold"
            mb="4"
            textAlign="center"
            textTransform="uppercase"
          >
            + {data!.totalScore} points
          </Text>
        </Box>
        {isEmpty(data!.responses) ? (
          <EmptySection
            emptyContent="This week was not scored because you did not answer any questions"
            innerContainerVariant="default"
          />
        ) : (
          <Box p="4">
            <RosePicks episode={sanityEpisode!} episodeEntry={data!} />
            <EpisodeQuestions episode={sanityEpisode!} episodeEntry={data!} />
          </Box>
        )}
      </Box>
    )
  )
}
