import { UserDocument } from '@guess-the-rose/firestore'
import { Spinner } from '@guess-the-rose/web-shared'
import { useRouter } from 'next/router'
import React, { ComponentType, FC } from 'react'

import { AUTH_REDIRECT_URL } from '../const'
import { useAuth } from '../context/useAuth'
import { safeLocalStorage } from '../lib'

type UnauthedState = {
  isAuthed: false
  isLoading: boolean
  user: null
  token: undefined
}

type AuthedState = {
  isAuthed: true
  isLoading: boolean
  user: any
  token: string
}

export type AuthState = UnauthedState | AuthedState

export type WithAuthProps = {
  user: UserDocument
}

export const withAuth = <Props extends {} = {}>(
  Component: ComponentType<Props & WithAuthProps>,
): FC<Props & WithAuthProps> => (props) => {
  const { isLoading, user } = useAuth()
  const router = useRouter()

  if (isLoading) return <Spinner superCentered />
  if (user) return <Component {...props} user={user} />

  safeLocalStorage()?.setItem(AUTH_REDIRECT_URL, router.asPath)
  router.push('/login')
  return null
}
