import { APP_HOME_PATH } from '@guess-the-rose/core'
import { EntryDocument } from '@guess-the-rose/firestore'
import {
  Box,
  Button,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  SlideIn,
  Text,
} from '@guess-the-rose/web-shared'
import { useDocument } from '@nandorojo/swr-firestore'
import { useRouter } from 'next/router'
import React, { FC } from 'react'

import { handleLoadingError } from '../lib/handleLoadingError'
import { EntryForm } from './EntryForm'

type EditEntryModalProps = {
  isOpen: boolean
  onClose: () => void
  entryID: string
  groupID: string
}

export const EditEntryModal: FC<EditEntryModalProps> = ({
  isOpen,
  onClose,
  entryID,
  groupID,
}) => {
  const { data, error, update, deleteDocument } = useDocument<EntryDocument>(
    `groups/${groupID}/entries/${entryID}`,
  )
  const router = useRouter()

  const loadingError = handleLoadingError({ data, error })
  if (loadingError) return loadingError

  return (
    // @ts-ignore
    <SlideIn in={isOpen}>
      {/*
      // @ts-ignore */}
      {(styles: any) => (
        <Modal isCentered isOpen={true} onClose={onClose}>
          <ModalOverlay opacity={styles.opacity} />
          <ModalContent px={'4'} py={'8'} {...styles}>
            <ModalCloseButton />
            <ModalBody>
              <Heading
                as="h2"
                margin="0 auto"
                maxWidth="280px"
                mb={[6]}
                size="lg"
                textAlign={['center']}
              >
                Edit Entry
              </Heading>
              <EntryForm
                buttonText="Save"
                initialValues={{
                  name: data!.name,
                }}
                onSubmit={async (values, { setSubmitting }) => {
                  await update(values)
                  setSubmitting(false)
                  onClose()
                }}
              />

              <Box mt="10">
                <Button
                  color="pink.500"
                  display="block"
                  margin="0 auto"
                  onClick={async () => {
                    await deleteDocument()

                    router.push(APP_HOME_PATH)
                  }}
                  size="sm"
                  textTransform="initial"
                  type="button"
                  variant="link"
                >
                  Delete My Entry?
                </Button>
                <Text
                  fontSize="sm"
                  fontStyle="italic"
                  margin="0 auto"
                  maxWidth="330px"
                  textAlign="center"
                >
                  Your entry will be deleted and you will no longer be a member
                  of this group. This action cannot be undone.
                </Text>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </SlideIn>
  )
}
