import { GlobalMetaDocument } from '@guess-the-rose/firestore'
import { useDocument } from '@nandorojo/swr-firestore'
import React, { ComponentType, FC } from 'react'

import { handleLoadingError } from '../lib'

export type WithMetaProps = {
  meta: GlobalMetaDocument
}

export const withMeta = <Props extends {} = {}>(
  Component: ComponentType<Props>,
): FC<Props> => (props) => {
  const { data, error } = useDocument<GlobalMetaDocument>('/meta/meta', {
    listen: true,
  })

  const loadingError = handleLoadingError({
    data,
    error,
    showSpinner: true,
  })

  if (loadingError) return loadingError

  return <Component {...props} meta={data} />
}
