import { FormControlProps, SwitchProps } from '@chakra-ui/core'
import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Switch as SwitchCore,
  Text,
} from '@guess-the-rose/web-shared'
import { useField } from 'formik'
import React, { ReactNode, forwardRef } from 'react'
import { Required } from 'utility-types'

export type GTRSwitchProps = Required<SwitchProps, 'name'> &
  Pick<FormControlProps, 'isDisabled' | 'isReadOnly' | 'isRequired'> & {
    helperText?: ReactNode
    formLabel?: ReactNode
  }

export const Switch = forwardRef<any, GTRSwitchProps>(
  (
    {
      name,
      isDisabled,
      isReadOnly,
      isRequired = true,
      helperText,
      formLabel,
      ...rest
    },
    ref,
  ) => {
    const [field, meta] = useField({ name, type: 'checkbox' })
    const isError = meta.touched && !!meta.error

    return (
      <FormControl
        isDisabled={isDisabled}
        isInvalid={isError}
        isReadOnly={isReadOnly}
        mb="6"
      >
        <Box display="flex">
          <SwitchCore
            color="pink"
            id={field.name}
            isChecked={field.checked}
            mr="4"
            name={field.name}
            onBlur={field.onBlur}
            onChange={field.onChange}
            ref={ref}
            size="lg"
            value={field.value}
            {...rest}
          />
          <Box>
            {formLabel && (
              <FormLabel htmlFor={field.name} mb="0">
                {formLabel}
                {!isRequired ? (
                  <Text as="span" fontStyle="italic">
                    &ndash; Optional
                  </Text>
                ) : null}
              </FormLabel>
            )}
            {helperText && (
              <FormHelperText id={field.name} mt="0">
                {helperText}
              </FormHelperText>
            )}
          </Box>
        </Box>
        {isError && <FormErrorMessage>{meta.error}</FormErrorMessage>}
      </FormControl>
    )
  },
)
