import styled from '@emotion/styled'
import { Contestant } from '@guess-the-rose/firestore'
import {
  Box,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  SlideIn,
  Text,
} from '@guess-the-rose/web-shared'
// @ts-ignore
import BlockContent from '@sanity/block-content-to-react'
import React, { FC, useEffect } from 'react'

import { useFirebaseAnalytics } from '../lib'

const BlockWrapper = styled.div`
  p {
    margin-bottom: 1rem;
  }
`

type ShareGroupModalProps = {
  isOpen: boolean
  onClose: () => void
  contestant: Contestant
}

export const ContestantModal: FC<ShareGroupModalProps> = ({
  isOpen,
  onClose,
  contestant,
}) => {
  const analytics = useFirebaseAnalytics()

  useEffect(() => {
    if (isOpen) {
      analytics?.logEvent('contestantModalOpened', {
        contestantName: contestant.name,
        contestantID: contestant._id,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    // @ts-ignore
    <SlideIn in={isOpen}>
      {/*
      // @ts-ignore */}
      {(styles: any) => (
        <Modal
          isCentered
          isOpen={true}
          onClose={onClose}
          scrollBehavior="inside"
        >
          <ModalOverlay opacity={styles.opacity} />
          <ModalContent px={'4'} py={'8'} {...styles}>
            <ModalCloseButton />
            <ModalBody>
              <Image
                alt={contestant.images[0].caption}
                borderRadius="5px"
                display="block"
                height="200px"
                margin="0 auto"
                mb="6"
                objectFit="cover"
                src={contestant.images[0].url}
                width="200px"
              />
              <Text
                fontSize="1.2rem"
                fontWeight="bold"
                mb="4"
                textAlign="center"
                textTransform="uppercase"
              >
                {contestant.name}
              </Text>
              <Text textAlign="center">
                {contestant.age}
                {contestant.career ? `, ${contestant.career}` : ''}
              </Text>
              {contestant.location && (
                <Text mb="6" textAlign="center">
                  {contestant.location}
                </Text>
              )}
              <BlockWrapper>
                <BlockContent
                  blocks={contestant.body}
                  dataset={process.env.NEXT_PUBLIC_SANITY_DATASET_NAME}
                  projectId={process.env.NEXT_PUBLIC_SANITY_PROJECT_ID}
                />
              </BlockWrapper>
              <Box>
                {contestant.instagram && (
                  <Text mb="2">
                    <Link
                      isExternal
                      color="pink.500"
                      href={contestant.instagram}
                      py="2"
                    >
                      Instagram
                    </Link>
                  </Text>
                )}
                {contestant.twitter && (
                  <Text mb="2">
                    <Link
                      isExternal
                      color="pink.500"
                      href={contestant.twitter}
                      py="2"
                    >
                      Twitter
                    </Link>
                  </Text>
                )}
                {contestant.website && (
                  <Text mb="2">
                    <Link
                      isExternal
                      color="pink.500"
                      href={contestant.website}
                      py="2"
                    >
                      Website
                    </Link>
                  </Text>
                )}
                {contestant.youtube && (
                  <Text mb="2">
                    <Link
                      isExternal
                      color="pink.500"
                      href={contestant.youtube}
                      py="2"
                    >
                      YouTube
                    </Link>
                  </Text>
                )}
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </SlideIn>
  )
}
