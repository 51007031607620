import { GlobalMetaDocument } from '@guess-the-rose/firestore'
import { UnreachableCaseError } from '@guess-the-rose/utils'
import { Callout, Section, SectionProps } from '@guess-the-rose/web-shared'
import React, { FC, ReactNode } from 'react'

import { withMeta } from '../hocs'

export const HowItWorksCallout: FC<SectionProps> = withMeta<SectionProps>(
  // @ts-ignore Meta there just HOC typing sucks
  ({ meta, ...rest }) => {
    let copy: ReactNode
    const { currentSeasonStatus } = meta as GlobalMetaDocument

    switch (currentSeasonStatus) {
      case 'preseason':
        copy =
          'Gameplay starts after episode one! Click here to see how it works.'
        break
      case 'drafting':
        copy =
          'Make sure to make your final rose selections and invite everyone to your group before episode two starts!'
        break
      case 'playing':
        copy =
          "Picks are now available for the upcoming episode! Click here to review rules and scoring and don't forget to submit your selections before the episode begins."
        break
      case 'complete':
        copy =
          'Thank you for playing this season. Join us for The Bachelor happening soon!'
        break

      default:
        throw new UnreachableCaseError(currentSeasonStatus)
    }

    return (
      <Section {...rest}>
        <a href="/how-it-works">
          <Callout showChevron>{copy}</Callout>
        </a>
      </Section>
    )
  },
)
