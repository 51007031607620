import styled from '@emotion/styled'
import React, { FC } from 'react'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'

import { useFirebaseAnalytics } from '../lib'

const ShareIconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > button {
    margin: 0 3px;
    cursor: pointer;
  }
`

export const ShareIcons: FC<{ url: string }> = ({ url }) => {
  const analytics = useFirebaseAnalytics()

  return (
    <ShareIconsContainer>
      <FacebookShareButton
        onClick={() => {
          analytics?.logEvent('shareGroupClicked', {
            medium: 'facebook',
            url,
          })
        }}
        quote="I just created a free Bachelor fantasy league on Guess the Rose. Join my group!"
        url={url}
      >
        <FacebookIcon round={true} size={32} />
      </FacebookShareButton>
      <LinkedinShareButton
        onClick={() => {
          analytics?.logEvent('shareGroupClicked', {
            medium: 'linkedin',
            url,
          })
        }}
        title="I just created a free Bachelor fantasy league on Guess the Rose. Join my group!"
        url={url}
      >
        <LinkedinIcon round={true} size={32} />
      </LinkedinShareButton>
      <TwitterShareButton
        hashtags={['GuesstheRose', 'TheBachelor']}
        onClick={() => {
          analytics?.logEvent('shareGroupClicked', {
            medium: 'twitter',
            url,
          })
        }}
        title="I just created a free Bachelor fantasy league on Guess the Rose. Join my group!"
        url={url}
      >
        <TwitterIcon round={true} size={32} />
      </TwitterShareButton>
      <WhatsappShareButton
        onClick={() => {
          analytics?.logEvent('shareGroupClicked', {
            medium: 'whatsapp',
            url,
          })
        }}
        url={url}
      >
        <WhatsappIcon round={true} size={32} />
      </WhatsappShareButton>
      <RedditShareButton
        onClick={() => {
          analytics?.logEvent('shareGroupClicked', {
            medium: 'reddit',
            url,
          })
        }}
        title="Guess the Rose, a free Bachelor fantasy league"
        url={url}
      >
        <RedditIcon round={true} size={32} />
      </RedditShareButton>
      <EmailShareButton
        body={`I just created a free Bachelor fantasy league on Guess the Rose! Join my group by clicking this link: ${url}`}
        onClick={() => {
          analytics?.logEvent('shareGroupClicked', {
            medium: 'email',
            url,
          })
        }}
        subject="Join my Bachelor Fantasy Group"
        url={url}
      >
        <EmailIcon round={true} size={32} />
      </EmailShareButton>
    </ShareIconsContainer>
  )
}
