import { BoxProps } from '@chakra-ui/core'
import { Question } from '@guess-the-rose/firestore'
import { Box } from '@guess-the-rose/web-shared'
import { useFormikContext } from 'formik'
import React, { FC } from 'react'

import { MultiSelectOption } from './MultiSelect'
import { QuestionHeader } from './sharedQuestionComponents'

type MultipleChoiceQuestionProps = BoxProps & {
  question: Question
}

export const MultipleChoiceQuestion: FC<MultipleChoiceQuestionProps> = ({
  question,
  ...rest
}) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<any>()
  const selectedValue = values[question._id]

  return (
    <Box {...rest}>
      <QuestionHeader question={question} />
      {question.questionConfiguration.multipleChoiceOptions!.map(
        (option, index) => (
          <MultiSelectOption
            isSelected={selectedValue === index}
            key={index}
            onClick={() => {
              setFieldValue(question._id, index)
              setTimeout(() => setFieldTouched(question._id, true))
            }}
          >
            {option}
          </MultiSelectOption>
        ),
      )}
    </Box>
  )
}
