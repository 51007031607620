import { BoxProps } from '@chakra-ui/core'
import { Question } from '@guess-the-rose/firestore'
import { Box } from '@guess-the-rose/web-shared'
import { useFormikContext } from 'formik'
import React, { FC } from 'react'

import { MultiSelectOption } from './MultiSelect'
import { QuestionHeader } from './sharedQuestionComponents'

type BooleanQuestionProps = BoxProps & {
  question: Question
}

const OPTIONS = [
  { option: 'Yes', value: 1 },
  { option: 'No', value: -1 },
]

export const BooleanQuestion: FC<BooleanQuestionProps> = ({
  question,
  ...rest
}) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<any>()
  const selectedValue = values[question._id]

  return (
    <Box {...rest}>
      <QuestionHeader question={question} />
      {OPTIONS.map((option) => (
        <MultiSelectOption
          isSelected={selectedValue === option.value}
          key={option.value}
          onClick={() => {
            setFieldValue(question._id, option.value)
            setTimeout(() => setFieldTouched(question._id, true))
          }}
        >
          {option.option}
        </MultiSelectOption>
      ))}
    </Box>
  )
}
