import { BoxProps } from '@chakra-ui/core'
import { EntryDocument } from '@guess-the-rose/firestore'
import { Icon, Link, Section, Tooltip } from '@guess-the-rose/web-shared'
import { Document } from '@nandorojo/swr-firestore'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import React, { FC } from 'react'

import { useAuth } from '../context/useAuth'
import { ContestantIcon } from './ContestantIcon'
import { Table } from './Table'

type Entry = Document<EntryDocument>

type EntriesListProps = BoxProps & {
  entries: Entry[]
}

export const EntriesList: FC<EntriesListProps> = ({ entries }) => {
  const { user } = useAuth()
  const router = useRouter()
  const columns = React.useMemo(
    () => [
      {
        Header: 'Rank',
        accessor: 'rank',
        emptyValue: 1,
      },
      {
        Header: 'Name',
        accessor: 'name',
        // eslint-disable-next-line react/display-name
        Cell: ({ value, row }: any) => {
          const entry = row?.original as Entry | undefined

          return entry && user?.userID === entry.createdBy ? (
            <>
              <NextLink
                as={`${router.asPath}/entries/${entry.id}`}
                href="/groups/[group]/entries/[entry]"
              >
                <Link tabIndex={1} textDecoration="underline">
                  {value}
                </Link>
              </NextLink>
              {entry?.entryUpdatedAfterEpisodeStart && (
                <Tooltip
                  aria-label="Edited after premiere"
                  fontSize="sm"
                  label="Entry edited/submitted answers after finale premiered."
                  mr="2"
                >
                  <Icon name="info" />
                </Tooltip>
              )}
            </>
          ) : (
            <span>
              {value}{' '}
              {entry?.entryUpdatedAfterEpisodeStart && (
                <Tooltip
                  aria-label="Edited after premiere"
                  fontSize="sm"
                  label="Entry edited/submitted answers after finale premiered."
                  mr="2"
                >
                  <Icon name="info" />
                </Tooltip>
              )}
            </span>
          )
        },
      },
      {
        Header: 'Rose',
        accessor: 'finalRose',
        // eslint-disable-next-line react/display-name
        Cell: ({ value }: any) => {
          return value ? <ContestantIcon contestantID={value} /> : ''
        },
      },
      {
        Header: 'Points',
        accessor: 'score',
      },
      {
        Header: 'Percentile',
        accessor: 'percentile',
      },
      {
        Header: 'Global Percentile',
        accessor: 'globalPercentile',
      },
    ],
    [router.asPath, user?.userID],
  )

  const featuredAndUserEntries = entries.filter(
    (e) => e.isFeatured || e.createdBy === user?.userID,
  )

  return (
    <>
      {featuredAndUserEntries.length > 0 && (
        <Section>
          <Table
            columns={columns}
            data={featuredAndUserEntries}
            showPagination={false}
            useTableConfig={{
              initialState: {
                sortBy: [
                  {
                    id: 'rank',
                    asc: true,
                  },
                ],
              },
            }}
          />
        </Section>
      )}
      <Section>
        <Table
          columns={columns}
          data={entries}
          useTableConfig={{
            initialState: {
              sortBy: [
                {
                  id: 'rank',
                  asc: true,
                },
              ],
            },
          }}
        />
      </Section>
    </>
  )
}
