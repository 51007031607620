import { BoxProps } from '@chakra-ui/core'
import { DRAFT_CLOSE_DATE, PREMIER_DATE } from '@guess-the-rose/core'
import {
  Box,
  Heading,
  Section,
  Text,
  useInterval,
} from '@guess-the-rose/web-shared'
import countdownTimer from 'countdown'
import React, { FC, ReactNode, useState } from 'react'

import { withMeta } from '../hocs'
import { isInOrAfterSeason } from '../lib'

type CountdownProps = BoxProps & {}

const Number: FC<{ duration: number; label: ReactNode }> = ({
  duration,
  label,
}) => {
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      minWidth={['80px', null, null, '170px']}
    >
      <Text color="white" fontSize="32px" lineHeight={1} marginBottom="10px">
        {duration}
      </Text>
      <Text color="white" fontSize="16px" lineHeight={1}>
        {label}
      </Text>
    </Box>
  )
}

export const Countdown: FC<CountdownProps> = withMeta<any>(({ meta }) => {
  const [countdown, setCountdown] = useState<{
    days: number
    hours: number
    minutes: number
  }>({ days: 0, hours: 0, minutes: 0 })

  const dateToCountdownTo =
    meta.currentSeasonStatus === 'drafting' ? DRAFT_CLOSE_DATE : PREMIER_DATE

  useInterval(
    () => {
      setCountdown(
        // @ts-ignore Types aren't exported write from definitely typed
        countdownTimer(
          new Date(),
          dateToCountdownTo,
          countdownTimer.DAYS | countdownTimer.HOURS | countdownTimer.MINUTES,
        ),
      )
    },
    30000,
    true,
  )

  if (isInOrAfterSeason(meta.currentSeasonStatus)) return null

  return (
    <Section
      innerContainerProps={{
        display: [null, null, 'flex'],
        alignItems: [null, null, 'center'],
      }}
      outerContainerVariant="colorful"
    >
      <Box>
        <Heading
          as="h3"
          color="white"
          fontWeight="400"
          mb="2"
          size="lg"
          textAlign={['center', null, 'left']}
        >
          {meta.currentSeasonStatus === 'drafting'
            ? 'Last chance to create groups and invite your friends!'
            : 'There’s still time to invite your friends!'}
        </Heading>
        <Text
          color="white"
          fontSize="sm"
          fontStyle="italic"
          mb={['4', null, 0]}
          textAlign={['center', null, 'left']}
        >
          Groups and entries lock before the start of Episode 2
        </Text>
      </Box>
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        margin={['0 auto', null, null, 0]}
        maxWidth={['400px', null, null, 'inherit']}
      >
        <Number duration={countdown.days} label="Days" />
        <Number duration={countdown.hours} label="Hours" />
        <Number duration={countdown.minutes} label="Minutes" />
      </Box>
    </Section>
  )
})
