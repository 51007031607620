import { BoxProps } from '@chakra-ui/core'
import { Contestant } from '@guess-the-rose/firestore'
import {
  Box,
  Button,
  Icon,
  Image,
  Text,
  useDisclosure,
} from '@guess-the-rose/web-shared'
import React, { FC } from 'react'

import { ContestantModal } from '../ContestantModal'

type ContestantSelectQuestionProps = BoxProps & {
  contestants: Contestant[]
  maxNumberOfSelections: number
  values: string[]
  onSelect: (id: string) => void
}

export const ContestantItem: FC<{
  contestant: Contestant
  isSelected: boolean
  onSelect: (id: string) => void
}> = ({ contestant, isSelected, onSelect }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Box
        borderBottom="1px solid"
        borderColor="gray.100"
        display="flex"
        justifyContent="space-between"
        px="2"
        py="4"
      >
        <Box display="flex">
          <Box mr="2">
            <Image
              alt={contestant.images[0].caption}
              borderRadius="5px"
              cursor="pointer"
              height="60px"
              objectFit="cover"
              onClick={onOpen}
              src={contestant.images[0].url}
              width="60px"
            />
          </Box>
          <Box>
            <Text
              cursor="pointer"
              fontWeight="bold"
              onClick={onOpen}
              tabIndex={0}
              textDecoration="underline"
              textTransform="uppercase"
            >
              {contestant.name}
            </Text>
            <Text>
              {contestant.age}, {contestant.career}
            </Text>
          </Box>
        </Box>
        <Box alignItems="center" display="flex">
          <Button
            _active={{ backgroundColor: 'transparent' }}
            _focus={{ boxShadow: 'none' }}
            _hover={{ backgroundColor: 'transparent' }}
            alignItems="center"
            backgroundColor="transparent"
            display="flex"
            height="40px"
            justifyContent="center"
            onClick={() => onSelect(contestant._id)}
            p="1"
            type="button"
            variant="ghost"
            width="auto"
          >
            {isSelected ? (
              <Icon color="gray.300" name="selectedIcon" size="30px" />
            ) : (
              <Icon color="gray.300" name="plusIcon" size="25px" />
            )}
          </Button>
        </Box>
      </Box>
      <ContestantModal
        contestant={contestant}
        isOpen={isOpen}
        onClose={onClose}
      />
    </>
  )
}

export const ContestantSelectQuestion: FC<ContestantSelectQuestionProps> = ({
  contestants,
  onSelect,
  maxNumberOfSelections,
  values,
  ...rest
}) => {
  return (
    <Box mb="2" {...rest}>
      {maxNumberOfSelections > 0 && (
        <Text color="gray.500" fontStyle="italic" mb="4" textAlign="center">
          {values.length} of {maxNumberOfSelections} Selections Made
        </Text>
      )}
      {contestants.map((contestant) => (
        <ContestantItem
          contestant={contestant}
          isSelected={values.includes(contestant._id)}
          key={contestant._id}
          onSelect={onSelect}
        />
      ))}
      {maxNumberOfSelections > 0 && (
        <Text color="gray.500" fontStyle="italic" mt="4" textAlign="center">
          {values.length} of {maxNumberOfSelections} Selections Made
        </Text>
      )}
    </Box>
  )
}
