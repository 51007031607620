import { BoxProps } from '@chakra-ui/core'
import { Contestant, Question } from '@guess-the-rose/firestore'
import { UnreachableCaseError } from '@guess-the-rose/utils'
import { Box, Heading, Text } from '@guess-the-rose/web-shared'
import { useFormikContext } from 'formik'
import React, { FC } from 'react'

import { BooleanQuestion } from './Boolean'
import { ContestantSelectQuestion } from './ContestantSelect'
import { MultipleChoiceQuestion } from './MultipleChoice'
import { MultiSelectQuestion } from './MultiSelect'
import { NumericQuestion } from './Numeric'

type QuestionBlockProps = BoxProps & {
  contestants: Contestant[]
  question: Question
  questionNumber: number
  numberOfQuestions: number
}

// Very simple, but we should never have people choosing more than five.
const simpleNumberToWord = (number: number) => {
  const dictionary = {
    1: 'one',
    2: 'two',
    3: 'three',
    4: 'four',
    5: 'five',
    6: 'six',
    7: 'seven',
    8: 'eight',
    9: 'nine',
    10: 'ten',
    11: 'eleven',
    12: 'twelve',
    13: 'thirteen',
    14: 'fourteen',
    15: 'fifteen',
    16: 'sixteen',
    17: 'seventeen',
    18: 'eighteen',
    19: 'nineteen',
    20: 'twenty',
  }

  // @ts-ignore
  return dictionary[number]
}

const QuestionComponentByType: FC<{
  question: Question
  contestants: Contestant[]
}> = ({ question, contestants }) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext<{
    [x: string]: any
  }>()

  switch (question.questionConfiguration.questionType) {
    case 'boolean':
      return <BooleanQuestion question={question} />
    case 'multiSelect':
      return (
        <MultiSelectQuestion
          maxNumberOfSelections={
            question.questionConfiguration!.multiSelectNumberOfChoices!
          }
          question={question}
        />
      )
    case 'multipleChoice':
      return <MultipleChoiceQuestion question={question} />
    case 'numeric':
      return <NumericQuestion question={question} />
    case 'weeklyLineup':
      return (
        <ContestantSelectQuestion
          contestants={contestants}
          maxNumberOfSelections={
            question.questionConfiguration!.weeklyLineupNumberOfChoices!
          }
          onSelect={(contestantID: any) => {
            // Remove if already there, otherwise add
            const currentValues = values[question._id]
            setFieldValue(
              question._id,
              currentValues.includes(contestantID)
                ? currentValues.filter((val: string) => val !== contestantID)
                : [...currentValues, contestantID],
            )
            setTimeout(() => setFieldTouched(question._id, true))
          }}
          values={values[question._id]}
        />
      )

    default:
      // eslint-disable-next-line no-case-declarations
      const { questionType } = question.questionConfiguration
      throw new UnreachableCaseError(questionType)
  }
}

const WeeklyLineupHero: FC<BoxProps & { question: Question }> = ({
  question,
  ...rest
}) => {
  const numberOfChoices = question.questionConfiguration
    .weeklyLineupNumberOfChoices!

  return (
    <Box borderBottom="1px solid" borderColor="gray.100" {...rest}>
      <Heading as="h3" mb="4" size="md" textAlign="center">
        Rose Predictions
      </Heading>
      <Text mb="4" textAlign="center">
        Select{' '}
        <b>
          {simpleNumberToWord(numberOfChoices)} ({numberOfChoices})
        </b>
        &nbsp; contestants you think will receive a rose during this episode.
        Click on their photo for more details.
      </Text>
      <Text
        color="gray.500"
        fontSize="2"
        fontStyle="italic"
        mb="4"
        textAlign="center"
      >
        +{question.points} for each correct pick
      </Text>
    </Box>
  )
}

const QuestionBlockHero: FC<{
  question: Question
  questionNumber: number
  numberOfQuestions: number
}> = ({ question, questionNumber, numberOfQuestions, ...rest }) => {
  if (question.questionConfiguration.questionType === 'weeklyLineup') {
    return <WeeklyLineupHero question={question} />
  }

  const isPointsPerCorrectPickQuestion = [
    'weeklyLineup',
    'multiSelect',
  ].includes(question.questionConfiguration.questionType)

  return (
    <Box borderBottom="1px solid" borderColor="gray.100" {...rest}>
      <Heading as="h3" mb="4" size="md" textAlign="center">
        Episode Question {questionNumber} of {numberOfQuestions}
      </Heading>
      <Text
        color="gray.500"
        fontSize="2"
        fontStyle="italic"
        mb="4"
        textAlign="center"
      >
        +{question.points}{' '}
        {isPointsPerCorrectPickQuestion ? 'for each correct pick' : 'points'}
      </Text>
    </Box>
  )
}

export const QuestionBlock: FC<QuestionBlockProps> = ({
  question,
  contestants,
  questionNumber,
  numberOfQuestions,
  ...rest
}) => {
  return (
    <Box mb="4" {...rest}>
      <QuestionBlockHero
        numberOfQuestions={numberOfQuestions}
        question={question}
        questionNumber={questionNumber}
      />
      <Box py="6">
        <QuestionComponentByType
          contestants={contestants}
          question={question}
        />
      </Box>
    </Box>
  )
}
