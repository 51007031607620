import { BoxProps } from '@chakra-ui/core'
import {
  Badge,
  Box,
  Heading,
  Image,
  Section,
  Text,
} from '@guess-the-rose/web-shared'
import { CarouselProvider, DotGroup, Slide, Slider } from 'pure-react-carousel'
import React, { FC } from 'react'

import { useSanity } from '../hooks/useSanity'
import { handleLoadingError } from '../lib'

export type Blog = {
  author: {
    name: string
  }
  category: {
    name: string
  }
  thumbnail: {
    asset: {
      url: string
    }
  }
  thumbnailText?: string
  slug: string
  title: string
  _createdAt: string
  _id: string
}

type LatestFromBlogProps = BoxProps & {}

export const BlogItem: FC<{ blog: Blog } & BoxProps> = ({ blog, ...rest }) => {
  return (
    <a href={`/blog/${blog.slug}`}>
      <Box display="flex" height="100%" padding="2" {...rest}>
        <Box flexShrink={0} mr="2">
          <Image
            alt={blog.thumbnailText ?? blog.title}
            height="100px"
            objectFit="cover"
            src={blog.thumbnail.asset.url}
            width="100px"
          />
        </Box>
        <Box display="flex" flexDir="column" justifyContent="space-between">
          <Heading as="h4" size="sm">
            {blog.title}
          </Heading>
          <Box>
            <Badge flexGrow={0}>{blog.category.name}</Badge>
            <Text>By: {blog.author.name}</Text>
          </Box>
        </Box>
      </Box>
    </a>
  )
}

export const LatestFromBlog: FC<LatestFromBlogProps> = () => {
  const { data, error } = useSanity<Blog[]>(
    `*[_type == "article" && !(_id in path("drafts.**")) && private != true][0..5]{
      _id,_createdAt,title,slug,thumbnailText,
      thumbnail {
        asset->{url}
      },
      category->{name},
      author->{name}
    } |

      order(_createdAt desc)
    `,
  )

  const loadingError = handleLoadingError({ error, data })
  if (loadingError) return <Section>{loadingError}</Section>

  return (
    <>
      <Box display={['block', null, 'none']}>
        <CarouselProvider
          infinite
          naturalSlideHeight={140}
          naturalSlideWidth={335}
          totalSlides={data?.length ?? 0}
        >
          <Section noBottomPadding innerContainerVariant="framed">
            <Box margin="0 auto" maxWidth="330px">
              <Slider>
                {data!.map((blog, index) => {
                  return (
                    <Slide index={index} key={index}>
                      <BlogItem blog={blog} />
                    </Slide>
                  )
                })}
              </Slider>
            </Box>
          </Section>
          <Section noBottomPadding>
            <DotGroup />
          </Section>
        </CarouselProvider>
      </Box>

      <Section outerContainerProps={{ display: ['none', null, 'block'] }}>
        <Heading as="h3" mb="6" size="lg">
          On the Blog
        </Heading>

        <Box display="flex" flexWrap="wrap" position="relative">
          {data?.map((blog, index) => {
            return (
              <Box key={index} mb="4" pr="4" width={['50%', null, null, '33%']}>
                <BlogItem
                  background="white"
                  blog={blog}
                  borderColor="gray.100"
                  borderRadius="md"
                  borderStyle="solid"
                  borderWidth="1px"
                  width="100%"
                />
              </Box>
            )
          })}
        </Box>
      </Section>
    </>
  )
}
