import { BoxProps } from '@chakra-ui/core'
import { APP_HOME_PATH } from '@guess-the-rose/core'
import { Box, Link, useTheme } from '@guess-the-rose/web-shared'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import React, { ComponentType, FC } from 'react'
import { FiBookOpen, FiCheckSquare, FiGrid, FiHome } from 'react-icons/fi'

type AppNavProps = BoxProps & {}

type AppRoute = {
  name: string
  slug: string
  icon: ComponentType
}

const APP_ROUTES: AppRoute[] = [
  {
    name: 'Home',
    slug: APP_HOME_PATH,
    icon: FiHome,
  },
  {
    name: 'Groups',
    slug: '/groups',
    icon: FiGrid,
  },
  {
    name: 'Picks',
    slug: '/picks',
    icon: FiCheckSquare,
  },
  {
    name: 'Content',
    slug: '/content',
    icon: FiBookOpen,
  },
]

export const AppNav: FC<AppNavProps> = (props) => {
  const theme = useTheme()
  const router = useRouter()

  return (
    <Box
      backgroundColor="white"
      borderBottom="1px solid"
      borderColor="gray.100"
      bottom={[0, null, 'initial']}
      position="fixed"
      top={[null, null, theme.dimensions.desktopNavHeight]}
      width="100%"
      zIndex={1100}
    >
      <Box
        alignItems="center"
        display="flex"
        height={['65px', null, theme.dimensions.desktopNavHeight]}
        justifyContent={['space-between', null, 'flex-start']}
        margin="0 auto"
        maxWidth={theme.dimensions.maxContainerWidth}
        px={['2']}
        {...props}
      >
        {APP_ROUTES.map(({ icon: Icon, slug, name }) => {
          return (
            <Box
              alignItems={['center', null, 'center']}
              display={['flex', null, 'block']}
              height={['100%', null, 'auto']}
              key={slug}
              mr={[0, null, '4']}
              textAlign={['center', null, 'left']}
              width={['75px', null, 'auto']}
            >
              <NextLink href={slug}>
                <Link
                  alignItems="center"
                  // @ts-ignore
                  color={[
                    // Active colors
                    router.pathname.includes(slug)
                      ? theme.colors.brand.primary
                      : theme.colors.brand.textColor,
                    theme.colors.brand.textColor,
                  ]}
                  display="flex"
                  flexDirection="column"
                  fontSize={['sm', null, 'lg']}
                  justifyContent="center"
                  px="4"
                  py="2"
                  textDecoration={[
                    null,
                    router.pathname.includes(slug) ? 'underline' : 'initial',
                  ]}
                >
                  {/* https://chakra-ui.com/icon#using-an-icon-library */}
                  <Box
                    as={Icon}
                    display={['block', null, 'none']}
                    mb="1"
                    size={'22px'}
                  />
                  {name}
                </Link>
              </NextLink>
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
