import { BoxProps, ButtonProps } from '@chakra-ui/core'
import {
  CurrentSeasonStatus,
  EpisodeEntryDocument,
} from '@guess-the-rose/firestore'
import {
  Box,
  Button,
  EmptySection,
  Icon,
  Section,
  SectionProps,
  Text,
} from '@guess-the-rose/web-shared'
import { useDocument } from '@nandorojo/swr-firestore'
import React, { FC, useState } from 'react'

import { EntryDocumentWithID } from '../context'
import { WithMetaProps, withMeta } from '../hocs'
import { useSanity } from '../hooks/useSanity'
import { handleLoadingError } from '../lib'
import {
  PickSectionOpen,
  PickSectionPreseason,
  PicksSectionScored,
} from './PicksSectionComponents'

export type SanityEpisodePicksSection = {
  _id: string
  airDate: string
  description: string
  episodeNumberSeason: number
  name: string
  questionsCount: number
}

type PickSectionProps = BoxProps & {
  episode: SanityEpisodePicksSection
  entry: EntryDocumentWithID
  currentEpisode: SanityEpisodePicksSection
}

const PickSection: FC<PickSectionProps> = ({
  entry,
  episode,
  currentEpisode,
}) => {
  const { data, error } = useDocument<EpisodeEntryDocument>(
    `/groups/${entry.groupID}/entries/${entry.id}/episodeEntries/${episode._id}`,
  )
  const loadingError = handleLoadingError({ data, error })
  if (
    episode.episodeNumberSeason > currentEpisode.episodeNumberSeason ||
    (episode.episodeNumberSeason === currentEpisode.episodeNumberSeason &&
      episode.questionsCount === 0)
  ) {
    return (
      <EmptySection
        emptyContent="Questions are not available for this episode yet. Check back soon!"
        innerContainerVariant="default"
      />
    )
  }

  if (loadingError) {
    return (
      <Box minHeight="200px" p="6">
        {loadingError}
      </Box>
    )
  }

  if (episode?.questionsCount > 0) {
    if (data?.status === 'scored') {
      return <PicksSectionScored entry={entry} episode={episode} />
    }

    if (currentEpisode._id === episode._id) {
      return <PickSectionOpen entry={entry} episode={episode} />
    }
  }

  // Fallback
  return (
    <EmptySection
      emptyContent="Questions are locked. Scores will be updated after the show!"
      innerContainerVariant="default"
    />
  )
}

const PicksSectionNav: FC<{
  onPreviousButtonProps?: Omit<ButtonProps, 'ref' | 'children'>
  onNextButtonProps?: Omit<ButtonProps, 'ref' | 'children'>
  episode: SanityEpisodePicksSection
}> = ({ onPreviousButtonProps, onNextButtonProps, episode }) => {
  const episodeDate = new Date(episode.airDate)
  const month = episodeDate.getMonth() + 1
  const day = episodeDate.getDate()
  return (
    <Box
      alignItems="center"
      borderBottom="1px solid"
      borderColor="gray.200"
      display="flex"
      height="50px"
      justifyContent="space-between"
    >
      <Button
        {...onPreviousButtonProps}
        height="100%"
        pl={['4', null, '6']}
        type="button"
        variant="ghost"
      >
        <Icon name="chevron-left" size="20px" />
      </Button>
      <Text>
        <b>Episode {episode.episodeNumberSeason}</b>&nbsp;({month}/{day})
      </Text>
      <Button
        {...onNextButtonProps}
        height="100%"
        pr={['4', null, '6']}
        type="button"
        variant="ghost"
      >
        <Icon name="chevron-right" size="20px" />
      </Button>
    </Box>
  )
}

type PicksSectionProps = SectionProps & {
  currentSeasonStatus: CurrentSeasonStatus
  currentEpisode: string
  entry: EntryDocumentWithID
}

type PicksSectionComponentProps = PicksSectionProps & {
  episodes: SanityEpisodePicksSection[]
}

export const PicksSectionComponent: FC<PicksSectionComponentProps> = ({
  entry,
  currentEpisode: currentEpisodeID,
  episodes,
  currentSeasonStatus,
  ...rest
}) => {
  const currentEpisodeIndex = episodes.findIndex(
    (e) => e._id === currentEpisodeID,
  )
  // Set to the current episode in the meta data by default
  const [selectedEpisodeIndex, setSelectedEpisodeIndex] = useState(
    currentEpisodeIndex,
  )
  const selectedEpisode = episodes[selectedEpisodeIndex]
  const currentEpisode = episodes[currentEpisodeIndex]
  const isNextEpisode = episodes[selectedEpisodeIndex + 1]
  const isPreviousEpisode = episodes[selectedEpisodeIndex - 1]

  return (
    <Section
      innerContainerProps={{
        padding: '0 !important',
        position: 'relative',
      }}
      innerContainerVariant="framed"
      {...rest}
    >
      <PicksSectionNav
        episode={selectedEpisode}
        onNextButtonProps={{
          isDisabled: !isNextEpisode,
          onClick: () => setSelectedEpisodeIndex((i) => i + 1),
        }}
        onPreviousButtonProps={{
          isDisabled: !isPreviousEpisode,
          onClick: () => setSelectedEpisodeIndex((i) => i - 1),
        }}
      />
      <Box py="6">
        {currentSeasonStatus === 'preseason' && selectedEpisodeIndex === 0 ? (
          <PickSectionPreseason groupID={entry.groupID} />
        ) : selectedEpisodeIndex === 0 ? (
          <EmptySection
            emptyContent={
              <Text>
                Episode one is not scored because it is during the scouting
                period. Check out the current episode to enter your picks for
                the week.
              </Text>
            }
            innerContainerVariant="default"
          />
        ) : (
          <PickSection
            currentEpisode={currentEpisode}
            entry={entry}
            episode={selectedEpisode}
            // Something with use document throws permissions errors. Unmounting on change to prevent
            key={selectedEpisode._id}
          />
        )}
      </Box>
    </Section>
  )
}

export const PicksSection: FC<PicksSectionProps> = withMeta((props) => {
  // @ts-ignore
  const meta = props.meta as WithMetaProps['meta']

  const { data, error } = useSanity<SanityEpisodePicksSection[]>(
    `*[_type == "episode" && references("${meta.currentSeason}") && !(_id in path("drafts.**"))]{
      _id,
      name,
      description,
      episodeNumberSeason,
      airDate,
      "questionsCount": count(*[_type=='question' && references(^._id)])
    } |

      order(episodeNumberSeason asc)
    `,
  )

  const loadingError = handleLoadingError({ data, error })
  if (loadingError)
    return <Section innerContainerVariant="framed">{loadingError}</Section>

  return <PicksSectionComponent episodes={data!} {...props} />
})
