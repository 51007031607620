import useSWR from 'swr'

import { sanity } from '../lib/sanity'

export const useSanity = <Response extends {}>(query: string) => {
  const { data, error } = useSWR<Response>(query, (query) =>
    sanity.fetch(query),
  )

  return { data, error }
}
