import 'firebase/auth'

import { APP_HOME_PATH, APP_URL_PATH } from '@guess-the-rose/core'
import firebase from 'firebase/app'
import React, { useEffect, useState } from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'

import { AUTH_REDIRECT_URL } from '../const'
import { useConvertKit } from '../hooks/useConvertKit'
import { safeLocalStorage, useFirebaseAnalytics } from '../lib'

const getSignInURL = () => {
  const redirectURL = safeLocalStorage()?.getItem(AUTH_REDIRECT_URL)

  if (!redirectURL) return APP_HOME_PATH.substring(1)

  // Should never happen since this is a public URL and we set localstorage in withAuth. Just being cautious
  if (redirectURL === `/${APP_URL_PATH}/login`)
    return APP_HOME_PATH.substring(1)

  return `${redirectURL}`.substring(1)
}

export const FirebaseAuth = () => {
  const [authUser, setAuthUser] = useState<any>(null)
  // Do not SSR FirebaseUI, because it is not supported.
  // https://github.com/firebase/firebaseui-web/issues/213
  const [renderAuth, setRenderAuth] = useState(false)
  const [subscribeToNewsletter] = useConvertKit()
  const analytics = useFirebaseAnalytics()

  const firebaseAuthConfig = {
    signInFlow: 'popup',
    // Auth providers
    // https://github.com/firebase/firebaseui-web#configure-oauth-providers
    signInOptions: [
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.TwitterAuthProvider.PROVIDER_ID,
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    ],
    signInSuccessUrl: getSignInURL(),
    credentialHelper: 'none',
    callbacks: {
      signInSuccessWithAuthResult: (result: any) => {
        setAuthUser(result)

        return false
      },
    },
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setRenderAuth(true)
    }
  }, [])

  useEffect(() => {
    if (!authUser) return

    const authSuccessCallback = async () => {
      if (authUser.additionalUserInfo?.isNewUser && authUser.user?.email) {
        await Promise.all([
          subscribeToNewsletter(authUser.user?.email),
          analytics?.logEvent('sign_up', {
            method: authUser.user?.providerId,
          }),
        ])
      }

      window.location.href = getSignInURL()
    }

    authSuccessCallback()
  }, [authUser, analytics, subscribeToNewsletter])

  return (
    <div>
      {renderAuth ? (
        <StyledFirebaseAuth
          firebaseAuth={firebase.auth()}
          uiConfig={firebaseAuthConfig}
        />
      ) : null}
    </div>
  )
}
