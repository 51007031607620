import { BoxProps } from '@chakra-ui/core'
import { Contestant, EntryDocument } from '@guess-the-rose/firestore'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Heading,
  Scale,
  Text,
  useDisclosure,
  useToast,
} from '@guess-the-rose/web-shared'
import { useDocument } from '@nandorojo/swr-firestore'
import { captureException } from '@sentry/node'
import { Form, Formik } from 'formik'
import { useRouter } from 'next/router'
import React, { FC, useEffect } from 'react'
import { array, object, string } from 'yup'

import { useAuth } from '../../context'
import { useFieldValue } from '../../lib'
import { ContestantSelectQuestion } from './ContestantSelect'

type FinalRoseFormProps = BoxProps & {
  contestants: Contestant[]
}

const SubmitWithConfirm: FC<{
  isValid: boolean
  isSubmitting: boolean
  submitForm: any
}> = ({ isValid, isSubmitting, submitForm }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = React.useRef<any>()

  return (
    <>
      <Button
        height="100%"
        isDisabled={!isValid}
        isLoading={isSubmitting}
        mr="2"
        onClick={onOpen}
        type="button"
        variant="solid"
      >
        Make Selection
      </Button>
      {/*
      // @ts-ignore */}
      <Scale in={isOpen}>
        {/*
        //@ts-ignore */}
        {(styles) => (
          <AlertDialog
            isOpen={true}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
          >
            <AlertDialogOverlay opacity={styles.opacity} zIndex={1600} />
            <AlertDialogContent zIndex={1600} {...styles}>
              <AlertDialogHeader>
                Save your "One True Love" selection?
              </AlertDialogHeader>
              <AlertDialogCloseButton />
              <AlertDialogBody>
                Your pick to receive the final rose cannot be changed once
                selected. If The Bachelorette changes during the season you will
                be able to select again. Are you sure?
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button
                  onClick={onClose}
                  ref={cancelRef}
                  type="button"
                  variant="outline"
                >
                  Cancel
                </Button>
                <Button ml={3} onClick={submitForm}>
                  Submit
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        )}
      </Scale>
    </>
  )
}

export const FinalRoseForm: FC<FinalRoseFormProps> = ({
  contestants,
  ...rest
}) => {
  const router = useRouter()

  const { update } = useDocument<EntryDocument>(
    `/groups/${router.query.group}/entries/${router.query.entry}`,
  )
  const fieldValue = useFieldValue()
  const { user } = useAuth()

  const toast = useToast()

  return (
    <Formik<{ finalRose: string[] }>
      initialValues={{
        finalRose: [],
      }}
      onSubmit={async ({ finalRose }) => {
        await update({
          finalRose: finalRose[0],
          updatedBy: user?.userID,
          updated: fieldValue.serverTimestamp(),
        })?.catch((e) => {
          captureException(e)

          toast({
            title: 'Error',
            description: 'Please try again.',
            status: 'error',
            position: 'bottom-right',
            isClosable: true,
          })
        })

        router.push(router.pathname, router.asPath)
      }}
      validateOnChange={true}
      validationSchema={object().shape({
        finalRose: array().of(string()).required().max(1).min(1),
      })}
    >
      {({
        handleSubmit,
        isValid,
        validateForm,
        isSubmitting,
        values,
        setFieldTouched,
        setFieldValue,
        submitForm,
      }) => {
        // Hacky workaround for this trash Formik 2.0 Garbage
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          validateForm()
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        return (
          <Form onSubmit={handleSubmit}>
            <Box {...rest}>
              <Box mb="6">
                <Heading as="h3" mb="2" size="md" textAlign="center">
                  ONE TRUE LOVE
                </Heading>
                <Text mb="4" textAlign="center">
                  Who will receive this season’s final rose? If you predict
                  correctly, you’ll receive 50 bonus points at the end of the
                  season. You’ll only make this selection once and it cannot be
                  changed.
                </Text>
                <Text mb="2" textAlign="center">
                  Click on their photo for more details.
                </Text>
                <Text color="gray.500" fontStyle="italic" textAlign="center">
                  +50 pts if correct
                </Text>
              </Box>
              <ContestantSelectQuestion
                contestants={contestants}
                maxNumberOfSelections={1}
                onSelect={(contestantID: any) => {
                  setFieldValue('finalRose', [contestantID])
                  setTimeout(() => setFieldTouched('finalRose', true))
                }}
                values={values.finalRose}
              />
              <Box
                alignItems="center"
                display="flex"
                height="36px"
                justifyContent="center"
                margin="0 auto"
                maxWidth="360px"
                mb="6"
                mt="8"
              >
                <SubmitWithConfirm
                  isSubmitting={isSubmitting}
                  isValid={isValid}
                  submitForm={submitForm}
                />
              </Box>
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}
