import { EntryDocument } from '@guess-the-rose/firestore'
import { Button } from '@guess-the-rose/web-shared'
import { Form, Formik, FormikConfig } from 'formik'
import React, { FC, ReactNode } from 'react'
import { Required } from 'utility-types'
import { object, string } from 'yup'

import { Input } from '../components/formElements/Input'

type EntryDocumentFormFields = Pick<EntryDocument, 'name'>

type EntryFormProps = Required<
  Partial<FormikConfig<EntryDocumentFormFields>>,
  'onSubmit'
> & {
  buttonText?: ReactNode
}

export const EntryForm: FC<EntryFormProps> = ({
  buttonText = 'Create',
  ...rest
}) => {
  return (
    <Formik<EntryDocumentFormFields>
      validateOnMount
      initialValues={{
        name: '',
      }}
      validationSchema={object().shape({
        name: string()
          .required('Name is required.')
          .max(60, 'Cannot exceed 60 characters.'),
      })}
      {...rest}
    >
      {({ isValid, isSubmitting }) => {
        return (
          <Form>
            <Input formLabel="Name" name="name" />
            <Button
              display="block"
              isDisabled={!isValid}
              isLoading={isSubmitting}
              margin="0 auto"
              type="submit"
              variant="solid"
            >
              {buttonText}
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}
