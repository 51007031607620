import { APP_URL_PATH, SITE_URL } from '@guess-the-rose/core'
import { GroupDocument } from '@guess-the-rose/firestore'
import {
  Alert,
  Box,
  Button,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  SlideIn,
  Text,
  Tooltip,
  useClipboard,
} from '@guess-the-rose/web-shared'
import { useDocument } from '@nandorojo/swr-firestore'
import React, { FC, useEffect, useRef } from 'react'

import { useFirebaseAnalytics } from '../lib'
import { handleLoadingError } from '../lib/handleLoadingError'
import { ShareIcons } from './ShareIcons'

type ShareGroupModalProps = {
  isOpen: boolean
  onClose: () => void
  groupID: string
}

export const ShareGroupModal: FC<ShareGroupModalProps> = ({
  isOpen,
  onClose,
  groupID,
}) => {
  const groupURL = `${SITE_URL}${APP_URL_PATH}/groups/${groupID}`
  const { value, onCopy, hasCopied } = useClipboard(groupURL)
  const { data, error } = useDocument<GroupDocument>(`groups/${groupID}`)
  const inputRef = useRef<any>()
  const analytics = useFirebaseAnalytics()

  useEffect(() => {
    if (isOpen) {
      analytics?.logEvent('shareGroupModalOpened')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus()
      inputRef.current?.select()
    }, 200)
  })

  useEffect(() => {
    if (hasCopied) {
      analytics?.logEvent('shareGroupModalCopyClicked')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasCopied])

  return (
    // @ts-ignore
    <SlideIn in={isOpen}>
      {/*
      // @ts-ignore */}
      {(styles: any) => (
        <Modal isCentered isOpen={true} onClose={onClose}>
          <ModalOverlay opacity={styles.opacity} />
          <ModalContent px={'4'} py={'8'} {...styles}>
            <ModalCloseButton />
            <ModalBody>
              <Heading
                as="h2"
                margin="0 auto"
                maxWidth="280px"
                mb={[2]}
                size="lg"
                textAlign={['center']}
              >
                Invite others to join {data?.name}!
              </Heading>
              <Text
                margin="0 auto"
                maxWidth="280px"
                mb={[4]}
                textAlign={['center']}
              >
                Share the following link with friends or post on social media:
              </Text>
              <InputGroup mb="6" size="md">
                <Input isReadOnly pr="4.5rem" ref={inputRef} value={value} />
                <InputRightElement width="4.5rem">
                  <Tooltip
                    shouldWrapChildren
                    aria-label="Copy URL"
                    isOpen={hasCopied}
                    label={'Copied!'}
                    placement="top"
                    style={{ zIndex: 100000 }}
                  >
                    <Button
                      h="1.75rem"
                      height="auto"
                      onClick={onCopy}
                      size="sm"
                      width="auto"
                    >
                      <Icon name="copy" />
                    </Button>
                  </Tooltip>
                </InputRightElement>
              </InputGroup>
              <Box mb="4">
                <ShareIcons url={groupURL} />
              </Box>
              {handleLoadingError({ error, data, skeletons: 1 }) ??
                (data!.visibility !== 'public' && (
                  <Alert status="warning">
                    <span>
                      This group is <strong>{data!.visibility}</strong>. Be
                      careful where you share the link. Anyone on the internet
                      can access the group with the link.
                    </span>
                  </Alert>
                ))}
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </SlideIn>
  )
}
