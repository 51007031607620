import { BoxProps } from '@chakra-ui/core'
import { APP_HOME_PREFIXED_PATH } from '@guess-the-rose/core'
import { Box, Footer, Nav, useTheme } from '@guess-the-rose/web-shared'
import React, { FC } from 'react'

import { useAuth } from '../context/useAuth'
import { AppNav } from './AppNav'

type LayoutProps = {
  showNav?: boolean
  /**
   * Show the nav specific to the application.
   */
  showAppNav?: boolean
  showFooter?: boolean
  wrapperProps?: BoxProps
  containerProps?: BoxProps
  spacey?: boolean
}

export const Layout: FC<LayoutProps> = ({
  showFooter = true,
  showNav = true,
  showAppNav = true,
  spacey = true,
  wrapperProps,
  containerProps,
  children,
}) => {
  const theme = useTheme()
  const auth = useAuth()
  const appNavEnabled = showAppNav && auth.isAuthed

  return (
    <Box
      display="flex"
      flexDir="column"
      minHeight="100vh"
      paddingBottom={appNavEnabled ? ['65px', null, 0] : []}
      position="relative"
      {...wrapperProps}
    >
      {showNav && (
        <Nav
          homeLink={APP_HOME_PREFIXED_PATH}
          internalLinkComponent={'a'}
          internalLinkProp="href"
          logout={auth.logout}
        />
      )}
      {appNavEnabled && <AppNav />}

      <Box
        flex="1"
        marginTop={
          showNav && appNavEnabled
            ? [
                // App nav fixed bottom on mobile
                `${theme.dimensions.mobileNavHeight}`,
                null,
                // App nav fix below primary on desktop
                `calc(${theme.dimensions.desktopNavHeight} * 2)`,
              ]
            : showNav
            ? [
                theme.dimensions.mobileNavHeight,
                null,
                theme.dimensions.desktopNavHeight,
              ]
            : []
        }
        paddingBottom={spacey ? ['6', null, '10'] : []}
        paddingTop={spacey ? ['4', null, '6'] : []}
        {...containerProps}
      >
        {children}
      </Box>

      {showFooter && (
        <Footer internalLinkComponent={'a'} internalLinkProp={'href'} />
      )}
    </Box>
  )
}
