import { BoxProps, ButtonProps } from '@chakra-ui/core'
import {
  Contestant,
  EpisodeEntryDocument,
  Question,
  QuestionType,
} from '@guess-the-rose/firestore'
import { UnreachableCaseError, isEmpty, sortBy } from '@guess-the-rose/utils'
import { Box, Button, useToast } from '@guess-the-rose/web-shared'
import { useDocument } from '@nandorojo/swr-firestore'
import { captureMessage } from '@sentry/node'
import { Form, Formik } from 'formik'
import { useRouter } from 'next/router'
import React, { FC, ReactNode, useEffect, useState } from 'react'
import { array, number, object, string } from 'yup'

import { QUESTION_ORDER_ARRAY } from '../../const'
import { useAuth } from '../../context'
import { useFieldValue, useFirebaseAnalytics } from '../../lib'
import { QuestionBlock } from './QuestionBlock'

type QuestionsFormProps = BoxProps & {
  contestants: Contestant[]
  questions: Question[]
  responses: EpisodeEntryDocument['responses'] | undefined
  /**
   * Defaults to 0.
   */
  initialSelectedQuestionIndex?: number
}

const QuestionsNav: FC<{
  onPreviousButtonProps?: Omit<ButtonProps, 'ref' | 'children'>
  onNextButtonProps?: Omit<ButtonProps, 'ref' | 'children'>
  nextbuttonContent?: ReactNode
  previousButtonContent?: ReactNode
}> = ({
  onPreviousButtonProps,
  onNextButtonProps,
  nextbuttonContent = 'Next',
  previousButtonContent = 'Back',
}) => {
  return (
    <Box
      alignItems="center"
      display="flex"
      height="36px"
      justifyContent="space-between"
      margin="0 auto"
      maxWidth="360px"
    >
      <Button
        height="100%"
        mr="2"
        type="button"
        variant="outline"
        {...onPreviousButtonProps}
      >
        {previousButtonContent}
      </Button>
      <Button
        height="100%"
        ml="2"
        type="button"
        variant="solid"
        {...onNextButtonProps}
      >
        {nextbuttonContent}
      </Button>
    </Box>
  )
}

const getInitialValueByQuestionType = (questionType: QuestionType) => {
  switch (questionType) {
    case 'boolean':
      return null
    case 'multiSelect':
      return []
    case 'multipleChoice':
      return ''
    case 'numeric':
      return ''
    case 'weeklyLineup':
      return []

    default:
      throw new UnreachableCaseError(questionType)
  }
}

const getValidationSchemaByQuestionType = (question: Question) => {
  const { questionType } = question.questionConfiguration

  switch (questionType) {
    case 'boolean':
      return number().required('Field is required.').nullable()
    case 'multiSelect':
      // eslint-disable-next-line no-case-declarations
      const multiNumberOfChoices = question.questionConfiguration
        .multiSelectNumberOfChoices!

      return array()
        .of(number())
        .required('Field is required.')
        .min(
          multiNumberOfChoices,
          `Must select at least ${multiNumberOfChoices} choices.`,
        )
        .max(
          multiNumberOfChoices,
          `Must select at least ${multiNumberOfChoices} choices.`,
        )

    case 'multipleChoice':
      return number().min(0, 'Must be positive.').required('Field is required.')
    case 'numeric':
      return number()
        .typeError('Must be a number.')
        .required('Field is required.')
        .nullable()
    case 'weeklyLineup':
      // eslint-disable-next-line no-case-declarations
      const weeklyNumberOfChoices = question.questionConfiguration
        .weeklyLineupNumberOfChoices!
      return array()
        .of(string())
        .required('Field is required.')
        .min(
          weeklyNumberOfChoices,
          `Must select at least ${weeklyNumberOfChoices} contestants.`,
        )
        .max(
          weeklyNumberOfChoices,
          `Must select at least ${weeklyNumberOfChoices} contestants.`,
        )

    default:
      throw new UnreachableCaseError(questionType)
  }
}

export const QuestionsForm: FC<QuestionsFormProps> = ({
  questions,
  contestants,
  initialSelectedQuestionIndex = 0,
  responses,
  ...rest
}) => {
  const editMode = !isEmpty(responses)
  const router = useRouter()
  const analytics = useFirebaseAnalytics()
  const sortedQuestions = sortBy(
    (q) => QUESTION_ORDER_ARRAY.indexOf(q.questionConfiguration.questionType),
    questions,
  )
  const { update } = useDocument<EpisodeEntryDocument>(
    `/groups/${router.query.group}/entries/${router.query.entry}/episodeEntries/${router.query.episode}`,
  )
  const fieldValue = useFieldValue()
  const { user } = useAuth()
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(
    initialSelectedQuestionIndex,
  )
  const isNextQuestion = Boolean(sortedQuestions[selectedQuestionIndex + 1])
  const isPreviousQuestion = Boolean(sortedQuestions[selectedQuestionIndex - 1])
  const selectedQuestion = sortedQuestions[selectedQuestionIndex]
  const toast = useToast()
  const initialValues = editMode
    ? responses! // Checked by edit mode
    : questions.reduce(
        (accu, item) => ({
          ...accu,
          [item._id]: getInitialValueByQuestionType(
            item.questionConfiguration.questionType,
          ),
        }),
        {},
      )

  const validationSchemaObject = questions.reduce(
    (accu, item) => ({
      ...accu,
      [item._id]: getValidationSchemaByQuestionType(item),
    }),
    {},
  )
  // Comps don't have weekly selections represented as questions in the UI so
  // we filter in order to get the correct question number.
  const pureQuestions = sortedQuestions.filter(
    (q) => q.questionConfiguration.questionType !== 'weeklyLineup',
  )

  return (
    <Formik<{ [x: string]: any }>
      initialValues={initialValues}
      onSubmit={async (values) => {
        analytics?.logEvent(editMode ? 'picksEdited' : 'picksSubmitted', {
          episode: router.query.episode,
          group: router.query.group,
          entry: router.query.entry,
        })

        await update({
          responses: values,
          updatedBy: user?.userID,
          updated: fieldValue.serverTimestamp(),
          status: 'submitted',
        })?.catch(() => {
          captureMessage('Submit questions form failed')

          toast({
            title: 'Error',
            description: 'Please try again.',
            status: 'error',
            position: 'bottom-right',
            isClosable: true,
          })
        })

        if (editMode) {
          router.push(
            `/groups/[group]/entries/[entry]`,
            `/groups/${router.query.group}/entries/${router.query.entry}`,
          )
        } else {
          router.push(`${router.pathname}/success`, `${router.asPath}/success`)
        }
      }}
      validateOnChange={true}
      validationSchema={object().shape(validationSchemaObject)}
    >
      {({
        handleSubmit,
        isValid,
        errors,
        validateForm,
        submitForm,
        isSubmitting,
      }) => {
        // Hacky workaround for this trash Formik 2.0 Garbage
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          validateForm()
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])

        console.log(errors)
        return (
          <Form onSubmit={handleSubmit}>
            <Box {...rest}>
              <QuestionBlock
                contestants={contestants}
                numberOfQuestions={pureQuestions.length}
                question={selectedQuestion}
                questionNumber={
                  pureQuestions.findIndex(
                    (q) => q._id === selectedQuestion._id,
                  ) + 1
                }
              />
              {/* {!isNextQuestion && !isEmpty(errors) && (
                <Box mb="4">
                  <Text fontSize="16px" fontWeight="500">
                    Errors
                  </Text>
                  {sortedQuestions.map((q, i) => {
                    const error = errors[q._id]
                      ? Array.isArray(errors[q._id])
                        ? // @ts-ignore
                          errors[q._id]?.length > 0
                          ? // @ts-ignore
                            errors[q._id][0]
                          : null
                        : errors[q._id]
                      : null

                    return error ? (
                      <Box key={q._id} mb="2">
                        <Text>
                          {i === 0 ? 'Weekly Picks' : `Question ${i}`}
                        </Text>
                        <Text>{error}</Text>
                      </Box>
                    ) : null
                  })}
                </Box>
              )} */}
              <QuestionsNav
                nextbuttonContent={
                  isNextQuestion ? 'Next' : editMode ? 'Update' : 'Submit'
                }
                onNextButtonProps={{
                  onClick: () => {
                    if (isNextQuestion) {
                      return setSelectedQuestionIndex((i) => i + 1)
                    }
                    submitForm()
                  },
                  isDisabled:
                    (isNextQuestion && !!errors[selectedQuestion._id]) ||
                    (!isNextQuestion && !isValid),
                  isLoading: isSubmitting,
                  type: 'button',
                }}
                onPreviousButtonProps={{
                  isDisabled: !isPreviousQuestion,
                  onClick: () => setSelectedQuestionIndex((i) => i - 1),
                }}
              />
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}
