import { GroupDocument } from '@guess-the-rose/firestore'
import { Button } from '@guess-the-rose/web-shared'
import { Form, Formik, FormikConfig } from 'formik'
import React, { FC, ReactNode } from 'react'
import { Required } from 'utility-types'
import { boolean, object, string } from 'yup'

import { Input } from '../components/formElements/Input'
import { Switch } from '../components/formElements/Switch'
import { Textarea } from '../components/formElements/Textarea'

type GroupDocumentFormFields = Pick<GroupDocument, 'description' | 'name'> & {
  isUnlisted: boolean
}

type GroupFormProps = Required<
  Partial<FormikConfig<GroupDocumentFormFields>>,
  'onSubmit'
> & {
  buttonText?: ReactNode
}

export const GroupForm: FC<GroupFormProps> = ({
  buttonText = 'Create',
  ...rest
}) => {
  return (
    <Formik<GroupDocumentFormFields>
      validateOnMount
      initialValues={{
        description: '',
        name: '',
        isUnlisted: false,
      }}
      validationSchema={object().shape({
        name: string()
          .required('Name is required.')
          .max(60, 'Cannot be more than 60 characters.'),
        description: string().max(1000, 'Max length of 1000 characters.'),
        isUnlisted: boolean(),
      })}
      {...rest}
    >
      {({ isValid, isSubmitting }) => {
        return (
          <Form>
            <Input
              formLabel="Group Name"
              name="name"
              placeholder="E.x. Bachelorette Fanatics"
            />
            <Textarea
              formLabel="Group Description"
              isRequired={false}
              name="description"
              placeholder="Ex. Get ready! Let’s see who can predict this season best!"
            />
            <Switch
              formLabel="Make Group Unlisted?"
              helperText="Only those with an invite link will be able to join."
              name="isUnlisted"
            />
            <Button
              display="block"
              isDisabled={!isValid}
              isLoading={isSubmitting}
              margin="0 auto"
              type="submit"
              variant="solid"
            >
              {buttonText}
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}
