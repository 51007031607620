import { Question } from '@guess-the-rose/firestore'
import { Box, Heading, Text } from '@guess-the-rose/web-shared'
import React, { FC } from 'react'

type CorrectIconProps = {
  points: number
  unit?: 'increment' | 'decrement'
  size?: string
}

export const CorrectIcon: FC<CorrectIconProps> = ({
  points,
  unit = 'increment',
  size = '30px',
}) => {
  return (
    <svg height={size} version="1.1" viewBox="0 0 25 25" width={size}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <circle cx="12.5" cy="12.5" fill="#FF0458" id="Oval" r="12.5"></circle>
        <text
          dominantBaseline="middle"
          fill="#FFFFFF"
          fontFamily="Lato-Bold, Lato"
          fontSize="10"
          fontWeight="bold"
          id="+5"
          textAnchor="middle"
          x="50%"
          y="50%"
        >
          <tspan>
            {unit === 'increment' ? '+' : '-'}
            {points}
          </tspan>
        </text>
      </g>
    </svg>
  )
}

export const QuestionHeader: FC<{ question: Question }> = ({ question }) => {
  return (
    <Box mb="6">
      <Heading as="h3" mb="2" size="md">
        {question.question}
      </Heading>
      {question.helpText && (
        <Text color="gray.500" fontStyle="italic">
          {question.helpText}
        </Text>
      )}
    </Box>
  )
}
