/* eslint-disable react/display-name */
/* eslint-disable react/jsx-key */
/* eslint-disable unicorn/explicit-length-check */
import styled from '@emotion/styled'
import { Box, Button, Icon, Select, Text } from '@guess-the-rose/web-shared'
import matchSorter from 'match-sorter'
import React from 'react'
import {
  useExpanded,
  useFilters,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'

const TableWrapper = styled.div``

export const NumberCell = ({ value }) => {
  return value ? <span>{value}</span> : <span>&mdash;</span>
}

const EMPTY_VALUE = <span>&mdash;</span>

export const DefaultCell = ({
  value,
  column: { emptyValue = EMPTY_VALUE },
}) => {
  return value ? <span>{value}</span> : emptyValue
}

const TableContainer = styled.div`
  overflow-x: auto;
  margin-bottom: 1rem;

  table {
    border-spacing: 0;
    border: 1px solid ${(props) => props.theme.colors.gray[100]};
    background: ${(props) => props.theme.colors.white};
    border-radius: 5px;
    width: 100%;
    min-width: 500px;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    tr {
      th {
        text-transform: uppercase;
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid ${(props) => props.theme.colors.gray[100]};

      text-align: left;

      :last-child {
        border-right: 0;
      }
    }
  }
`

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length

  return (
    <input
      onChange={(e) => {
        setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
      value={filterValue || ''}
    />
  )
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val

export function Table({
  data,
  columns,
  showPagination = true,
  showFilters = false,
  useTableConfig = {},
}) {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    [],
  )

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
      Cell: DefaultCell,
    }),
    [],
  )

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      disableMultiSort: true,
      ...useTableConfig,
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
  )

  // Render the UI for your table
  return (
    <TableWrapper>
      <TableContainer>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps()}
                    {...(column.width
                      ? { style: { width: column.width } }
                      : {})}
                  >
                    <div>
                      <Text
                        alignItems="center"
                        display="flex"
                        {...column.getSortByToggleProps()}
                      >
                        {column.render('Header')}
                        {/* Add a sort direction indicator */}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <Icon name="arrow-down" size={15} />
                          ) : (
                            <Icon name="arrow-up" size={15} />
                          )
                        ) : (
                          ''
                        )}
                      </Text>
                    </div>
                    {/* Render the columns filter UI */}
                    {showFilters && (
                      <div>
                        {column.canFilter ? column.render('Filter') : null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {showPagination
              ? page.map((row) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })
              : rows.map((row) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
          </tbody>
        </table>
      </TableContainer>
      {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
      {showPagination && (
        <Box alignItems="center" display="flex" justifyContent="flex-start">
          <Select
            display="inline"
            flexGrow={0}
            onChange={(e) => {
              setPageSize(Number(e.target.value))
            }}
            size="sm"
            value={pageSize}
            width="auto"
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
          <Button
            height="auto"
            isDisabled={!canPreviousPage}
            mr="1"
            onClick={() => gotoPage(0)}
            size="xs"
            variant="ghost"
            width="auto"
          >
            {'<<'}
          </Button>
          <Button
            height="auto"
            isDisabled={!canPreviousPage}
            mr="1"
            onClick={() => previousPage()}
            size="xs"
            variant="ghost"
            width="auto"
          >
            {'<'}
          </Button>
          <Button
            height="auto"
            isDisabled={!canNextPage}
            mr="1"
            onClick={() => nextPage()}
            size="xs"
            variant="ghost"
            width="auto"
          >
            {'>'}
          </Button>
          <Button
            height="auto"
            isDisabled={!canNextPage}
            mr="1"
            onClick={() => gotoPage(pageCount - 1)}
            size="xs"
            variant="ghost"
            width="auto"
          >
            {'>>'}
          </Button>
          <Text display="inline" m="0">
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </Text>
        </Box>
      )}
    </TableWrapper>
  )
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id]
    return rowValue >= filterValue
  })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== 'number'
