import { BoxProps } from '@chakra-ui/core'
import { Question } from '@guess-the-rose/firestore'
import { Box } from '@guess-the-rose/web-shared'
import React, { FC } from 'react'

import { Input } from '../formElements/Input'
import { QuestionHeader } from './sharedQuestionComponents'

type NumericQuestionProps = BoxProps & {
  question: Question
}

export const NumericQuestion: FC<NumericQuestionProps> = ({
  question,
  ...rest
}) => {
  return (
    <Box {...rest}>
      <QuestionHeader question={question} />
      <Box>
        <Input name={question._id} />
      </Box>
    </Box>
  )
}
