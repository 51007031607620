import { BoxProps } from '@chakra-ui/core'
import { Contestant, GlobalMetaDocument } from '@guess-the-rose/firestore'
import { Avatar, Box, Text, useDisclosure } from '@guess-the-rose/web-shared'
import React, { FC } from 'react'

import { withMeta } from '../hocs'
import { useSanity } from '../hooks/useSanity'
import { handleLoadingError } from '../lib'
import { ContestantModal } from './ContestantModal'

type ContestantIconProps = BoxProps & {
  contestantID: string
  showName?: boolean
}

export const ContestantIcon: FC<ContestantIconProps> = withMeta(
  ({
    contestantID,
    showName = true,
    // @ts-ignore silly hoc
    meta,
    ...rest
  }) => {
    const metaDocument = meta as GlobalMetaDocument
    const { isOpen, onClose, onOpen } = useDisclosure()
    const { data, error } = useSanity<
      Contestant
    >(`*[_type == "character" && _id == "${contestantID}" && !(_id in path("drafts.**"))]{
    _id,
    name,
    body,
    age,
    career,
    location,
    excerpt,
    twitter,
    instagram,
    website,
    youtube,
    images[]{
      caption,
      "url": asset->{url}.url
    },
    seasons[]->{
      _id,
      name,
      seasonNumber,
      show
    }
  }[0]
`)
    const {
      data: sanityEpisodeShort,
      error: sanityEpisodeShortError,
    } = useSanity<{
      _id: string
      name: string
      contestants: {
        _id: string
        name: string
      }[]
    }>(`*[_type == "episode" && _id == "${metaDocument.currentEpisode}" && !(_id in path("drafts.**"))]{
      _id,
      name,
      contestants[]->{
        _id,
        name,
      },
      }[0]
`)

    const loadingError = handleLoadingError({
      data: data && sanityEpisodeShort,
      error: error && sanityEpisodeShortError,
      showSpinner: true,
    })
    const isEliminated = !sanityEpisodeShort?.contestants.find(
      (c) => c._id === contestantID,
    )

    return (
      <Box {...rest} position="relative">
        {loadingError ?? (
          <>
            <Box alignItems="center" display="flex">
              <Avatar
                cursor="pointer"
                mr="2"
                onClick={onOpen}
                opacity={isEliminated ? 0.4 : null}
                size="xs"
                src={data!.images[0].url}
              />
              {showName && (
                <Text
                  fontSize="12px"
                  opacity={isEliminated ? 0.4 : null}
                  textDecoration={isEliminated ? 'line-through' : null}
                >
                  {data!.name}
                </Text>
              )}
            </Box>
            <ContestantModal
              contestant={data!}
              isOpen={isOpen}
              onClose={onClose}
            />
          </>
        )}
      </Box>
    )
  },
)
