import { BoxProps } from '@chakra-ui/core'
import {
  EntryDocument,
  GlobalMetaDocument,
  GroupDocument,
} from '@guess-the-rose/firestore'
import {
  Box,
  Button,
  Link as ChakraLink,
  Heading,
  Section,
  Text,
  useDisclosure,
  useTheme,
} from '@guess-the-rose/web-shared'
import { useDocument } from '@nandorojo/swr-firestore'
import Link from 'next/link'
import { CarouselProvider, DotGroup, Slide, Slider } from 'pure-react-carousel'
import React, { FC } from 'react'

import { useEntries } from '../context'
import { withMeta } from '../hocs'
import { isBeforeSeasonStart } from '../lib'
import { handleLoadingError } from '../lib/handleLoadingError'
import { ShareGroupModal } from './ShareGroupModal'

type EntryDocumentWithID = EntryDocument & { id: string }

type MyGroupsProps = BoxProps & {
  emptyText?: string
}

const GroupInfo: FC<{ entry: EntryDocumentWithID }> = withMeta(
  ({
    entry,
    // @ts-ignore
    meta,
  }) => {
    const { error, data: groupDocument } = useDocument<GroupDocument>(
      `/groups/${entry.groupID}`,
    )
    const metaDocument: GlobalMetaDocument = meta
    const { isOpen, onOpen, onClose } = useDisclosure()

    const loadingError = handleLoadingError({
      error,
      data: groupDocument,
      skeletons: 1,
      skeletonProps: { width: '200px', height: '15px', my: '4' },
    })

    if (loadingError) return loadingError

    return (
      <>
        <Box flex="1">
          <Heading
            as="h4"
            mb="2"
            size="md"
            textAlign={['center', null, 'left']}
          >
            {groupDocument!.name}
          </Heading>
        </Box>

        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          padding=""
        >
          <Link as={`/groups/${entry.groupID}`} href="/groups/[group]">
            <Button as="a" flex="1" mr="2" variant="outline">
              View Group
            </Button>
          </Link>

          {isBeforeSeasonStart(metaDocument.currentSeasonStatus) ? (
            <Button flex="1" ml="2" onClick={onOpen} variant="solid">
              Invite
            </Button>
          ) : (
            <Link
              as={`/groups/${entry.groupID}/entries/${entry.id}`}
              href="/groups/[group]/entries/[entry"
            >
              <Button as="a" flex="1" mr="2" variant="solid">
                View Entry
              </Button>
            </Link>
          )}

          <ShareGroupModal
            groupID={entry.groupID}
            isOpen={isOpen}
            onClose={onClose}
          />
        </Box>
      </>
    )
  },
)

export const MyGroups: FC<MyGroupsProps> = ({
  emptyText = (
    <Text>
      No groups joined.{' '}
      <Link href="/join-a-group">
        <ChakraLink
          color="pink.500"
          textDecoration="underline"
          textTransform="initial"
        >
          Join a group
        </ChakraLink>
      </Link>{' '}
      or create one to play!
    </Text>
  ),
}) => {
  const theme = useTheme()
  const { entries, error } = useEntries()

  return (
    <>
      <Box display={['block', null, 'none']}>
        <CarouselProvider
          infinite
          naturalSlideHeight={140}
          naturalSlideWidth={335}
          totalSlides={entries?.length ?? 0}
        >
          <Section noBottomPadding innerContainerVariant="framed">
            {handleLoadingError({ data: entries, error }) ??
              (entries!.length === 0 ? (
                <Text textAlign="center">{emptyText}</Text>
              ) : (
                <Box margin="0 auto" maxWidth="330px">
                  <Slider>
                    {entries!.map((entry, index) => {
                      return (
                        <Slide index={index} key={index}>
                          <Box
                            display="flex"
                            flexDir="column"
                            height="100%"
                            padding="2"
                          >
                            <GroupInfo entry={entry} />
                          </Box>
                        </Slide>
                      )
                    })}
                  </Slider>
                </Box>
              ))}
          </Section>
          <Section noBottomPadding>
            <DotGroup />
          </Section>
        </CarouselProvider>
      </Box>

      <Section
        innerContainerVariant="framed"
        outerContainerProps={{ display: ['none', null, 'block'] }}
      >
        <Box position="relative">
          {handleLoadingError({ data: entries, error }) ??
            (entries!.length === 0 ? (
              <Text textAlign="left">{emptyText}</Text>
            ) : (
              entries!.map((entry, i) => {
                return (
                  <Box
                    alignItems="center"
                    borderBottom={`1px solid ${theme.colors.gray[100]}`}
                    borderColor="gray.100"
                    display="flex"
                    justifyContent="space-between"
                    key={i}
                    py="6"
                  >
                    <GroupInfo entry={entry} />
                  </Box>
                )
              })
            ))}
        </Box>
      </Section>
    </>
  )
}
