import sanityClient from '@sanity/client'

if (
  !process.env.NEXT_PUBLIC_SANITY_PROJECT_ID ||
  !process.env.NEXT_PUBLIC_SANITY_DATASET_NAME
) {
  throw new Error('Must pass Sanity environmental variables!')
}

export const sanity = sanityClient({
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET_NAME,
  useCdn: true, // `false` if you want to ensure fresh data
})
