import { captureException } from '@sentry/browser'

/**
 * Helper to parse the referrer URL if there is one.
 * This is pretty dumb at the moment because there could be valuable if the referrer
 * is an internal page or param? We could potentially append certain params at channels
 * to get better insights on where people are coming in from.
 */
export function getReferrer() {
  const a = document.createElement('a')
  a.href = document.referrer

  return { url: a.hostname ?? '', params: a.search ?? '' }
}

export const useConvertKit = () => {
  const subscribeToConvertKit = async (email: string) => {
    if (!process.env.NEXT_PUBLIC_CONVERT_KIT_SUBSCRIBE_URL) {
      console.warn(
        'GTR Warning: No NEXT_PUBLIC_CONVERT_KIT_SUBSCRIBE_URL found. Form will not be submitted. Add one to your environment configuration to use this feature.',
      )
      return
    }

    try {
      const referrer = getReferrer()
      const bodyString = JSON.stringify({
        email_address: email,
        tags: ['1832922'], // This scopes them to wave-snippets
        fields: {
          referrer: referrer.url,
          referrer_params: referrer.params,
          subscribed_url:
            typeof window !== 'undefined' && window.location.pathname,
        },
      })

      const response = await fetch(
        process.env.NEXT_PUBLIC_CONVERT_KIT_SUBSCRIBE_URL,
        {
          method: 'post',
          body: bodyString,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        },
      )

      const json = await response.json()

      if (json.status === 'success') {
        return
      }
    } catch (err) {
      captureException(err)
    }
  }

  return [subscribeToConvertKit]
}
