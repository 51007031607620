import { GroupDocument } from '@guess-the-rose/firestore'
import {
  Button,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Section,
} from '@guess-the-rose/web-shared'
import { Document, useCollection } from '@nandorojo/swr-firestore'
import NextLink from 'next/link'
import React, { FC } from 'react'

import { useSearch } from '../hooks/useSearch'
import { handleLoadingError } from '../lib/handleLoadingError'
import { Table } from './Table'

type Group = Document<GroupDocument>

type GroupsListComponentProps = {
  groups: Group[]
}

const GroupsListComponent: FC<GroupsListComponentProps> = ({ groups }) => {
  const { results, searchText, setSearchText } = useSearch({
    collection: groups,
    uidFieldName: 'id',
    indexes: ['name', 'description', 'tags'],
  })
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        // eslint-disable-next-line react/display-name
        Cell: ({ value, row }: any) => {
          const group = row?.original as Group | undefined

          return group?.id ? (
            <NextLink as={`/groups/${group.id}`} href="/groups/[group]">
              <Link tabIndex={1} textDecoration="underline">
                {value}
              </Link>
            </NextLink>
          ) : (
            <span>{value}</span>
          )
        },
      },
      {
        Header: 'Description',
        accessor: 'description',
      },
      {
        Header: 'Number of Members',
        accessor: 'totalMembers',
      },
    ],
    [],
  )

  return (
    <Section innerContainerVariant="framed">
      <InputGroup mb="4">
        <Input
          onChange={(e: any) => setSearchText(e.target.value)}
          placeholder="Search for a group..."
          value={searchText}
        />

        {searchText && (
          <InputRightElement>
            <Button
              _hover={{
                backgroundColor: 'white',
              }}
              background="white"
              height="auto"
              minWidth="initial"
              onClick={() => setSearchText('')}
              padding="0"
              variant="ghost"
              width="auto"
            >
              <Icon name="close" />
            </Button>
          </InputRightElement>
        )}
      </InputGroup>
      <Table columns={columns} data={results} />
    </Section>
  )
}

type GroupsListProps = {}

export const GroupsList: FC<GroupsListProps> = () => {
  const { data, error } = useCollection<GroupDocument>('/groups', {
    where: [['visibility', '==', 'public' as GroupDocument['visibility']]],
  })

  const loadingError = handleLoadingError({ data, error })
  if (loadingError) {
    return <Section innerContainerVariant="framed">{loadingError}</Section>
  }

  return <GroupsListComponent groups={data!} />
}
