import { FormControlProps, InputProps } from '@chakra-ui/core'
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Text,
  Textarea as TextareaCore,
} from '@guess-the-rose/web-shared'
import { useField } from 'formik'
import React, { ReactNode, forwardRef } from 'react'
import { Required } from 'utility-types'

export type GTRTextareaProps = Required<
  InputProps<HTMLTextAreaElement>,
  'name'
> &
  Pick<FormControlProps, 'isDisabled' | 'isReadOnly' | 'isRequired'> & {
    helperText?: ReactNode
    formLabel?: ReactNode
  }

// eslint-disable-next-line react/display-name
export const Textarea = forwardRef<any, GTRTextareaProps>(
  (
    {
      name,
      isDisabled,
      isReadOnly,
      isRequired = true,
      helperText,
      formLabel,
      ...rest
    },
    ref,
  ) => {
    const [field, meta] = useField(name)
    const isError = meta.touched && !!meta.error

    return (
      <FormControl
        isDisabled={isDisabled}
        isInvalid={isError}
        isReadOnly={isReadOnly}
        mb="6"
      >
        {formLabel && (
          <FormLabel htmlFor={field.name}>
            {formLabel}{' '}
            {!isRequired ? (
              <Text as="span" fontStyle="italic">
                &ndash; Optional
              </Text>
            ) : null}
          </FormLabel>
        )}
        <TextareaCore
          _focus={{
            borderColor: 'pink.500',
          }}
          id={field.name}
          name={field.name}
          onBlur={field.onBlur}
          onChange={field.onChange}
          ref={ref}
          value={field.value}
          {...rest}
        />
        {helperText && (
          <FormHelperText id={field.name}>{helperText}</FormHelperText>
        )}
        {isError && <FormErrorMessage>{meta.error}</FormErrorMessage>}
      </FormControl>
    )
  },
)
